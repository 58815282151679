<template>
  <div>
    <HeadVue @loaded="headLoaded"></HeadVue>
    <router-view v-if="isHeadLoaded"></router-view>
    <FooterVue
      :address="address"
      :siteID="siteID"
      :siteName="siteName"
      :company="company"
      v-if="isHeadLoaded"
    ></FooterVue>
  </div>
</template>
<script>
import HeadVue from '@/components/Head.vue';
import FooterVue from '@/components/Footer.vue';

export default {
  data() {
    return {
      title: '嘟嘟集运 - 跨国集运专家 | 海外华人留学生集运服务',
      keyWords: '嘟嘟集运, 包裹集运, 国货集运, 海外华人集运, 留学生集运服务, 淘宝双十一集运, 淘宝转运, 淘宝转寄海外, 淘宝集中转运, 集运服务, 中国到海外集运',
      description: '嘟嘟集运，您的跨国集运专家。专为海外华人和留学生提供便捷、高效的集运服务。无论您身在何处，享受家乡商品的乐趣。轻松、快捷、安全，让您的海外生活更加丰富多彩。加入我们，探索更多集运优惠和服务。',
      address: '',
      siteID: '',
      siteName: '',
      company: '',
      isHeadLoaded: false,
    }
  },

  // metaInfo() {
  //   return {
  //     title: this.title, // set a title
  //     meta: [
  //       {
  //         // set meta
  //         name: "keyWords",
  //         content: this.keyWords,
  //       },
  //       {
  //         name: "description",
  //         content: this.description,
  //       },
  //     ],
  //   };
  // },

  metaInfo: {
    title: '嘟嘟集运 - 跨国集运专家 | 海外华人留学生集运服务',
    meta: [{
      name: 'Keywords',
      content: '靠谱集运,靠谱马来西亚集运,淘宝集运,拼多多集运,抖音集运,小红书集运,京东集运,temu集运,tiktok集运,海南嘟嘟,嘟嘟快递,嘟嘟货代,嘟嘟集运,嘟嘟国际快递集运转运,嘟嘟国际集运转运,东南亚物流,东南亚集运,马来西亚空运,马来西亚集运推荐,马来西亚华人,马来西亚海运,新加坡物流,新加坡空运,新加坡集运推荐,新加坡华人,新加坡海运,中国寄马来西亚,中国寄新加坡,中国寄泰国,泰国空运,泰国集运推荐,泰国华人,泰国海运,马来西亚tng支付,马来西亚代付'
    },
    {
      name: 'Description',
      content: '嘟嘟集运，您的跨国集运专家。专为海外华人和留学生提供便捷、高效的集运服务。无论您身在何处，享受家乡商品的乐趣。轻松、快捷、安全，让您的海外生活更加丰富多彩。加入我们，探索更多集运优惠和服务。'
    }]
  },

  components: {
    HeadVue,
    FooterVue,
  },

  mounted() {
    let that = this;
    //获取站点信息
    that.$axios.get('site/get_site_newList?cid=33&page=1&limit=5').then(res => {
      that.title = res.data.data.list[0].title || '';//站点名称
      that.keyWords = res.data.data.list[1].title || '';//站点关键词
      // that.address = res.data.data.list[3].title || '';//公司地址
      that.siteID = res.data.data.list[2].title || '';//站点备案号
      that.siteName = res.data.data.list[1].title || '';//集运名称
      that.company = res.data.data.list[0].title || '';//集运名称
    })
  },
  methods:{
    headLoaded() {
      this.isHeadLoaded = true;
    }
  },

};
</script>


<style>
@font-face {
  font-family: "fontSimHei";
  src: url("./assets/font/simheittf.ttf") format("truetype");
  /* 可选：添加其他字体样式 */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SourceHanSansSC";
  src: url("./assets/font/SourceHanSansSC-Regular.otf") format("truetype");
  /* 可选：添加其他字体样式 */
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "SourceHanSansSC";
}

:root {
  --theme-color: #FFB62E; /* 主题色 */
  --theme-color-rgba: 255,182,46; /* 主题色rgb */
  --theme-font-color-bg: #FFB62E; /*字体背景颜色*/
  --theme-color-bg: #FFB62E; /* 主题背景色 */
  --theme-color-title: #fff; /*主题标题字体 */
  --title-color: #fff;
  --linear-btn-bg: #333333;
  --linear-btn-text: #333333;
  --mb-head-btn-bg: #f4f4f4;
  --footer-text-color: #fff;
  --footer-text-active-color: #999;
}

.mb-head-btn-bg {
  background-color: var(--mb-head-btn-bg);
}

.theme-color-border {
  border: 1px solid var(--theme-color-bg) !important;
}

.theme-color {
  color: var(--theme-color) !important;
}

.theme-color-bg {
  background-color: var(--theme-color-bg) !important;
}

.theme-color-title {
  color: var(--theme-color-title);
}

.them-color-font-bg {
  background-color: var(--theme-font-color-bg) !important;
}

.theme_tips {
  color: var(--theme_tips) !important;
}

.theme_tips_bg {
  background-color: var(--theme_tips_bg) !important;
}

p {
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
